// Functions from vendors/jquery.unveil.js

$(function () {
  // Trigger not working without setTimeout hack.
  setTimeout(function () {
    $('.product_img_change img').unveil(100);
    $(document).trigger('lookup');
  }, 50);
  $('.interier-design_li img').unveil(200);
  $('.sliders_box img').unveil();
  $(document).trigger('lookup');
});
